<template>
  <div class="login">
    <div class="login__container _container">
      <h1 class="login__title main-title _green">Авторизация</h1>
      <div class="login__body">
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          class="login__form"
          v-slot="{ errors }"
        >
          <div class="login__item">
            <label for="email">Email</label>
            <Field
              name="email"
              id="email"
              :class="{ _error: errors.hasOwnProperty('email') }"
              type="email"
              v-model="email"
              @input="showError = false"
              class="login__input main-input"
            />
            <transition name="fade">
              <span class="error-text" v-if="errors.hasOwnProperty('email')">{{
                errors.email
              }}</span>
            </transition>
          </div>
          <div class="login__item">
            <label for="password">{{ $t("login.password") }}</label>
            <div
              class="login__password"
              :class="{ _error: errors.hasOwnProperty('password') }"
            >
              <Field
                name="password"
                id="password"
                @input="showError = false"
                v-model="password"
                :type="showInput ? 'text' : 'password'"
                class="login__input main-input"
              />
              <button type="button" @click="showInput = !showInput">
                <span><img src="@/assets/icons/form/eye.svg" alt="" /></span>
              </button>
            </div>
            <transition name="fade">
              <span
                class="error-text"
                v-if="errors.hasOwnProperty('password')"
                >{{ errors.password }}</span
              >
            </transition>
          </div>
          <div class="login__item checkbox-item">
            <div class="checkbox-item__group">
              <Field
                name="acceptTerms"
                type="checkbox"
                class="checkbox-item__input"
                :value="true"
                id="remember"
              />
              <span class="checkbox-item__icon"></span>
              <label
                for="remember"
                v-for="dataproc in footer.dataproc"
                :key="dataproc.id"
                >{{ $t("login.rulesRU") }}
                <router-link
                  to="/rule/dataproc"
                  class="checkbox-item__text"
                  @click="openRulePage(dataproc)"
                  >{{ $t("login.rulesLink") }}
                </router-link>
                {{ $t("login.rulesKZ") }}
              </label>
              <transition name="fade">
                <span
                  class="error-text"
                  v-if="errors.hasOwnProperty('acceptTerms')"
                  >{{ errors.acceptTerms }}</span
                >
              </transition>
            </div>
          </div>

          <div class="login__item">
            <button class="login__btn blue-button" type="submit">
              <span>{{ $t("auth") }}</span>
            </button>
            <transition name="fade">
              <span class="error-message" v-if="showError">{{
                $t("login.passwordError")
              }}</span>
            </transition>
          </div>
        </Form>
        <div class="login__additional">
          <router-link to="/forgot-password" class="login__link">{{
            $t("login.forgotPassword")
          }}</router-link>
        </div>

        <div class="login__bottom">
          <span class="login__text">{{ $t("login.haveAccount") }} </span>
          <router-link to="/registration" class="login__link">
            {{ $t("login.register") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapMutations, mapActions, mapState } from "vuex";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

export default {
  data() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email(this.$t("errors.email"))
        .required(this.$t("errors.required"))
        .label()
        .nullable(),
      password: Yup.string()
        .min(5, this.$t("errors.minLenghtPassword", { value: 5 }))
        .required(this.$t("errors.required"))
        .label()
        .nullable(),
      acceptTerms: Yup.string().required(this.$t("login.paymentRules")),
    });
    return {
      email: null,
      password: null,
      schema,
      showInput: false,
      showError: false,
    };
  },
  components: {
    Field,
    Form,
  },
  computed: {
    ...mapState(["footer"]),
  },
  methods: {
    ...mapMutations(["SET_USER", "SET_RULE"]),
    ...mapActions(["checkAuth"]),
    onSubmit() {
      axios
        .post("https://admin.san-remo.kz/api/V1/login-user", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("access_token", res.data.accessToken);
            this.checkAuth();
            if (localStorage.getItem("payment") == "true") {
              this.$router.push("/buy-ticket");
            } else {
              this.$router.push("/personal-account");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.showError = true;
        });
    },
    openRulePage(object) {
      this.SET_RULE(object);
    },
  },
};
</script>

<style lang="scss">
.login {
  background: #E8F1F3;
  padding: 50px 0 70px 0;

  @media (max-width: 991.98px) {
    padding: 41px 0;
  }

  @media (max-width: $mobileSmall + px) {
    padding: 32px 0;
  }

  &__title {
    margin: 0 0 100px 0;

    @media (max-width: $tablet + px) {
      margin: 0 0 55px 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 71px 0;
    }
  }

  &__body {
    margin: 0 auto;

    @media (min-width: $tablet + px) {
      max-width: 650px;
      background: #ffffff;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
      border-radius: 60px;
      padding: 50px 40px;
    }
  }

  &__form {
    margin: 0 0 50px 0;

    @media (max-width: $tablet + px) {
      margin: 0 0 37px 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 24px 0;
    }
  }

  &__item {
    position: relative;

    label {
      display: block;
      margin: 0 0 8px 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 136.7%;
      letter-spacing: 0.02em;
      color: #339CB599;

      @media (max-width: $tablet + px) {
        font-size: 17px;
      }

      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
        margin: 0 0 5px 0;
      }
    }

    &:not(:last-child) {
      margin: 0 0 35px 0;

      @media (max-width: $tablet + px) {
        margin: 0 0 27px 0;
      }

      @media (max-width: $mobileSmall + px) {
        margin: 0 0 24px 0;
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__link {
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #339CB599;
    margin: 0 0 5px 0;

    @media (max-width: $tablet + px) {
      font-size: 16px;
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 134.61%;
    letter-spacing: 0.02em;
    color: #339CB599;

    @media (max-width: $tablet + px) {
      font-size: 26px;
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__additional {
    text-align: center;
    margin: 0 0 80px 0;

    @media (max-width: $tablet + px) {
      margin: 0 0 64px 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 48px 0;
    }
  }

  &__bottom {
    text-align: center;
  }

  &__socials {
    margin: 24px 0 0 0;

    @media (max-width: $tablet + px) {
      margin: 20px 0 0 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 16px 0 0 0;
    }

    &_second {
      @media (min-width: $mobile + px) {
        display: none;
      }
    }
  }

  &__social {
    &,
    img {
      display: inline-block;
      border-radius: 50%;
      min-width: 30px;
      min-height: 30px;
      width: 38px;
      height: 38px;

      @media (max-width: $tablet + px) {
        width: 34px;
        height: 34px;
      }

      @media (max-width: $mobileSmall + px) {
        width: 30px;
        height: 30px;
      }
    }

    &:not(:last-child) {
      margin: 0 24px 0 0;

      @media (max-width: $tablet + px) {
        margin: 0 20px 0 0;
      }

      @media (max-width: $mobileSmall + px) {
        margin: 0 16px 0 0;
      }
    }
  }

  &__password {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #339CB599;
    padding: 0 24px;
    border-radius: 15px;
    background: white;
    input {
      border: none;
      padding: 0;
    }

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;

      @media (max-width: $mobileSmall + px) {
        width: 20px;
        height: 20px;
      }

      img {
        width: 21.03px;
        height: 13.5px;

        @media (max-width: $mobileSmall + px) {
          width: 16.02px;
          height: 11.67px;
        }
      }
    }
  }

  .checkbox-item {
    &__group {
      display: flex;
      align-items: center;
    }

    &__input {
      position: absolute;
      opacity: 0;
      z-index: 1;
      width: 20px;
      height: 20px;

      @media (max-width: $mobileSmall + px) {
        width: 16px;
        height: 16px;
      }

      &:checked ~ .checkbox-item__icon {
        &::after {
          opacity: 1;
        }
      }
    }

    &__icon {
      background: #ffffff;
      border: 1px solid #339CB5;
      border-radius: 4px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $mobileSmall + px) {
        width: 18px;
        height: 18px;
      }

      &::after {
        content: url("@/assets/icons/form/checkmark.svg");
        transition: opacity 0.25s ease 0s;
        opacity: 0;
      }
    }

    label {
      display: inline-block;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: #339CB5;
      margin: 0 0 0 12px;

      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
        margin: 0 0 0 8px;
      }
    }

    &__text {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .error-message {
    @media (max-width: $mobileSmall + px) {
      bottom: 10px;
    }
  }
}
</style>
